import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Switch,
  CircularProgress,
  Backdrop,
  IconButton,
  useTheme,
} from "@mui/material";
import { ArrowBack, Delete } from "@mui/icons-material";
import { NavigationConstants } from "../../constants/NavigationConstants";
import { SingleScanAnalysisDetails } from "./SingleScanAnalysisDetails";
import { ScanAnalysisSteps } from "./ScanAnalysisSteps";
import { Toast } from "../common/Toast";
import { AiqTooltip } from "../common/AiqToolTip";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { SelectedScanAnalysisVMSelector } from "../../app/selectors/singleScanAnalysisSelectors"
import { enhancedApi } from "../../app/api/enhancedApi";
import { AiqConfigurations } from "../../redux/aiqConfigurations";
import { setViewedScanAnalysis } from "../../app/slices/scanAnalysisSlice";
import { useDeleteApiScanAnalysesByIdMutation, useGetApiSingleScanApplicationsQuery } from "../../app/api/aiq-api";
import { useRef } from "react";


export const SingleScanAnalysis: React.FunctionComponent = (props) => {
  const navigate = useNavigate();
  const [loadSingleScanAnalysisVM]  = enhancedApi.endpoints.getApiScanAnalysisViewModelsById.useLazyQuery();
  const singleScanAnalysisVM = useSelector((state: ApplicationState) => SelectedScanAnalysisVMSelector(state as never));
  const selectedSingleScanAnalysisId = useSelector((state: ApplicationState) => state.scanAnalyses.edit.viewedScanAnalysisId);
  const [showNittyGrittyDetails, setShowNittyGrittyDetails] = React.useState<boolean>(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState<boolean>(false);
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  useGetApiSingleScanApplicationsQuery();
  const dispatch = useDispatch();
  const params = useParams<{ slug: string }>();
  const [deleteScanAnalysis] = useDeleteApiScanAnalysesByIdMutation({ fixedCacheKey: 'shared-scan-analysis-delete' });
  const theme = useTheme();

  const autoRefreshTimer = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

  const refetchSingleScanAnalysis = () => {
    if(selectedSingleScanAnalysisId) {
      loadSingleScanAnalysisVM({id: selectedSingleScanAnalysisId});
    }
  }

  const forceRefresh = () => {
    refetchSingleScanAnalysis();
  }

  const createAutoRefreshTimer = () => {
    console.log("Creating auto-refresh timer for single scan analysis");
    autoRefreshTimer.current = setInterval(() => {
      console.log("Auto-refreshing single scan analysis");
      forceRefresh();
    }, AiqConfigurations.scanStatusRefreshIntervalSecs * 1000);
    return () => clearInterval(autoRefreshTimer.current);
  };

  const destroyAutoRefreshTimer = () => {
    if (autoRefreshTimer.current) {
      console.log("Destroying auto-refresh timer for single scan analysis");
      clearInterval(autoRefreshTimer.current);
      autoRefreshTimer.current = undefined;
    }
  };

  const resetAutoRefreshTimer = (refreshImmediately: boolean = false) => {
    destroyAutoRefreshTimer();
    if (refreshImmediately) {
      forceRefresh();
    }
    return createAutoRefreshTimer();
  };

  React.useEffect(() => {
    let analysisIdFromUrl = params.slug;
    if(analysisIdFromUrl) {
      dispatch(setViewedScanAnalysis(analysisIdFromUrl));
    }

    // createAutoRefreshTimer();

    return () => {
      destroyAutoRefreshTimer();
    };
  }, []);

  React.useEffect(() => {
    resetAutoRefreshTimer(true);
  }, [selectedSingleScanAnalysisId]);

  const handleDeleteClicked = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    let id = singleScanAnalysisVM?.scanAnalysis.id as string;
    setSnackbarText(`Deleting Scan Analysis ${id}`);
    deleteScanAnalysis({ id: id });
    navigate(`${NavigationConstants.singleScanProcessingPath}?studyId=${singleScanAnalysisVM?.study.id}`);
  };

  const renderDeleteConfirmationModal = () => {
    return (
      <ConfirmationDialog
        open={deleteConfirmationOpen}
        onCancel={() => setDeleteConfirmationOpen(false)}
        onConfirm={() => handleDeleteConfirmed()}
        confirmButtonLabel="Delete"
        title="Delete Scan Analysis"
        text={`Do you want to delete Scan Analysis ${singleScanAnalysisVM?.scanAnalysis.id}
      for patient ${singleScanAnalysisVM?.patient.patientIdentifier}
      at timepoint ${singleScanAnalysisVM?.timepoint.timepointName}?`}
      ></ConfirmationDialog>
    );
  }

  return (
    <React.Fragment>
      <Backdrop
        sx={{zIndex: theme.zIndex.drawer + 1, color: "#fff"}}
        open={!singleScanAnalysisVM}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {singleScanAnalysisVM && (
        <React.Fragment>
          <Grid data-cy="SingleScanAnalysis" container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ mr: 1 }} gutterBottom>
                <IconButton onClick={() => navigate(-1)} color="primary" size="large">
                  {" "}
                  <ArrowBack />
                </IconButton>
              </Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography
                    variant="h5"
                    sx={{ mr: 1 }}
                    gutterBottom
                  >
                    Scan Analysis
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    sx={{ mr: 1 }}
                    gutterBottom
                  >
                    {singleScanAnalysisVM.scanAnalysis.id}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{
              display: "flex",
              flexWrap: "wrap" as "wrap",
              alignItems: "center",
              justifyContent: "flex-end"
              }}>
                <Typography variant="subtitle1" gutterBottom sx={{
                  textAlign: "right" as "right",
                  marginRight: "2px",
                  fontSize: 14,
                  color: theme.palette.primary.light
                  }}>
                  Show All Details:
                </Typography>
                <Switch
                  data-cy="ShowAllDetailsButton"
                  size="small"
                  checked={showNittyGrittyDetails}
                  onChange={(event) => {
                    setShowNittyGrittyDetails(event.target.checked);
                  }}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />{" "}
                <AiqTooltip title="Delete Scan Analysis">
                  <IconButton
                    data-cy="DeleteScanAnalysisButton"
                    onClick={() => handleDeleteClicked()}
                    sx={{ mr: 2, ml: 3}}
                    size="large">
                    <Delete />
                  </IconButton>
                </AiqTooltip>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <SingleScanAnalysisDetails
            scanAnalysisVM={singleScanAnalysisVM}
            showNittyGrittyDetails={showNittyGrittyDetails}
          />
          <ScanAnalysisSteps
            scanAnalysisVM={singleScanAnalysisVM}
            showNittyGrittyDetails={showNittyGrittyDetails}
            onForceRefresh={() => forceRefresh()}
          />
          <Toast
            open={snackbarText !== undefined}
            onClose={() => setSnackbarText(undefined)}
            text={snackbarText ?? ''}
          />
          {renderDeleteConfirmationModal()}
        </React.Fragment>
      )}
    </React.Fragment>
  );

}
