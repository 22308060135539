import * as React from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Divider,
  Backdrop,
  CircularProgress,
  useTheme,
  Box
} from "@mui/material";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { JSONTree } from "react-json-tree";
import { Study } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { ApplicationSelectMenu } from "./ApplicationSelectMenu";
import { useSelector } from "react-redux";
import { AllSingleScanApplicationsSelector } from "../../app/selectors/applicationSelectors";
import { SelectedStudy } from "../../app/selectors/studySelectors";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import { AiqConfigurations } from "../../redux/aiqConfigurations";

interface IScanPackageProcessingPopupProps {
  onCancel: () => void;
  selectedStudyId: string;
}

/**
 * This is a connected component that displays a list of Platform Studies
 */
export const ScanPackageProcessingPopup: React.FunctionComponent<IScanPackageProcessingPopupProps> = (
  props
) => {
  const applications = useSelector((state: ApplicationState) => AllSingleScanApplicationsSelector(state as never))
  const [selectedApplicationId, setSelectedApplicationId] = React.useState<string | undefined | null>(
    [...(applications ?? [])].find((x) => x != null && !x.deprecated && !x.disabled)?.systemId
  );
  const [doProcessPackage] = enhancedApi.endpoints.postApiScanpackagesProcessRequest.useMutation();
  const [doValidatePackage, validatePackageResult] = enhancedApi.endpoints.postApiScanpackagesValidation.useMutation();
  const selectedStudy: Study | undefined = useSelector((state: ApplicationState) => SelectedStudy(state as never));

  const theme = useTheme();

  const handleProcessSubmit = async (folderName: string, props: IScanPackageProcessingPopupProps) => {
    let fullPackageUrl = `${AiqConfigurations.scanPackageBaseUrl}${folderName}/`;
    doProcessPackage({
      scanPackageProcessRequest: {
        scanPackageLocation: fullPackageUrl,
        studyId: selectedStudy?.id,
        singleScanApplicationSystemId: selectedApplicationId,
        overwriteOriginal: false
      },
    });
    props.onCancel();
  };

  const handleValidationSubmit = async (folderName: string) => {
    let fullPackageUrl = `${AiqConfigurations.scanPackageBaseUrl}${folderName}/`;
    doValidatePackage({
      scanPackageProcessRequest: {
        scanPackageLocation: fullPackageUrl,
        studyId: selectedStudy?.id ?? null,
        singleScanApplicationSystemId: selectedApplicationId,
      },
    });
  };

  const handleApplicationSelectionChange = (selectedApplicationId: string | undefined) => {
    setSelectedApplicationId(selectedApplicationId);
  };

  return (
    <React.Fragment>
      <Paper sx={{ maxWidth: "1200px", padding: "10px" }}>
        <Backdrop
          sx={{ zIndex: theme.zIndex.drawer + 1, color: "#fff" }}
          open={validatePackageResult.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="h4" sx={{ mr: 1 }} gutterBottom>
              Load Scan Package
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        <Grid container justifyContent="flex-start">
          <Grid item xs={12}>
            <Typography variant="h6">
              Prepare a Scan Package including a Manifest and upload them to a fileshare in the Storage
              Account associated with this environment.
            </Typography>
            <Typography variant="h6">
              Enter name of the <b>folder</b> within the Scan Packages fileshare containing the scan manifest and all scan data to be loaded.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={5}>
            <ApplicationSelectMenu
              applications={applications ?? []}
              selectedApplicationId={selectedApplicationId ?? null}
              nullSelectLabel="Use Application from Manifest"
              onSelectionChanged={(applicationId: string | undefined) =>
                handleApplicationSelectionChange(applicationId)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{ manifestLocation: "" }}
              validationSchema={Yup.object({
                manifestLocation: Yup.string()
                  .required("Required"),
              })}
              onSubmit={(data, { setSubmitting }) => {
                handleProcessSubmit(data.manifestLocation, props);
                setSubmitting(false);
              }}
            >
              {({ submitForm, dirty, values }) => (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        data-cy="manifestLocation"
                        name="manifestLocation"
                        label="Scan Package Location"
                        fullWidth
                        autoFocus
                        variant="filled"
                        tabIndex="1"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {validatePackageResult && validatePackageResult.data?.success === true && (
                        <Typography variant="subtitle1" sx={{ maxHeight: 400, overflowY: "auto" }}>
                          Package appears valid
                        </Typography>
                      )}
                      {validatePackageResult && validatePackageResult.data?.success === false && (
                        <Typography variant="subtitle1" sx={{}}>
                          Package invalid: {validatePackageResult.data?.errorMessages?.join(",")}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {validatePackageResult.data && (
                        <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
                          <JSONTree data={validatePackageResult.data} />
                        </Box>
                      )}
                    </Grid>
                    {/*{!(!dirty || !validatePackageResult.data || !validatePackageResult.data?.success) && (*/}
                    {/*  <Grid item xs={12}>*/}
                    {/*    <FormControlLabel*/}
                    {/*        sx={{width: 1200}}*/}
                    {/*        control={*/}
                    {/*            <Checkbox*/}
                    {/*                data-cy="OverwriteOriginalCheckbox"*/}
                    {/*                size="small"*/}
                    {/*                checked={overwriteOriginal}*/}
                    {/*                onChange={(event) => setOverwriteOriginal(event.target.checked)}*/}
                    {/*                name="OverwriteOriginal"*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        label={*/}
                    {/*            <Typography variant="body2">*/}
                    {/*                Overwrite Original Manifest with updates from processing (disallow re-ingest)*/}
                    {/*            </Typography>*/}
                    {/*        }*/}
                    {/*    />*/}
                    {/*  </Grid>*/}
                    {/*)}*/}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          buttonBox: {
                            display: "flex",
                            flexFlow: "row wrap",
                            "& Button": {
                              marginRight: "10px",
                            },
                          },
                        }}
                      >
                        {/* Validate button */}
                        <Button
                          data-cy="validateButton"
                          variant="contained"
                          color="primary"
                          disabled={!dirty}
                          onClick={() => handleValidationSubmit(values.manifestLocation)}
                        >
                          Validate
                        </Button>{" "}
                        {/* Submit button */}
                        <Button
                          data-cy="processButton"
                          variant="contained"
                          color="primary"
                          disabled={
                            !dirty || !validatePackageResult.data || !validatePackageResult.data?.success
                          }
                          onClick={submitForm}
                        >
                          Process Package
                        </Button>{" "}
                        {/* Cancel button */}
                        <Button
                          data-cy="cancelButton"
                          variant="contained"
                          onClick={() => props.onCancel()}
                          color="secondary"
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
