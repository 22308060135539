import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationState } from "../../redux/store/ConfigureStore";

import { IPlatformUserFormViewModel, PlatformUserForm } from "./PlatformUserForm";
import { Typography, Paper, Divider, Container, CircularProgress, useTheme, Box } from "@mui/material";
import { Toast } from "../common/Toast";
import { useGetApiPlatformUsersQuery } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { getErrorMessageFromRtkMutationResult } from "../../app/api/apiUtilities";

/**
 * This is a connected container component meant to host dumb components displaying UI for a Platform User.
 */
export const ManagePlatformUser: React.FunctionComponent = () => {
  const params = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [selectedUserId, setSelectedUserId] = React.useState<string | undefined>(undefined);
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [userSaveError, setUserSaveError] = React.useState<string | undefined>(undefined);
  const [doAddUser, addUserResult] = enhancedApi.endpoints.postApiPlatformUsers.useMutation();
  const [doUpdateUser, updateUserResult] = enhancedApi.endpoints.putApiPlatformUsersById.useMutation();
  const selectedUser = useSelector((state: ApplicationState) => enhancedApi.endpoints.getApiPlatformUsersById.select({id: selectedUserId!})(state));
  const {refetch: refetchPlatformUsers} = useGetApiPlatformUsersQuery();
  const [loadSelectedUser]  = enhancedApi.endpoints.getApiPlatformUsersById.useLazyQuery();
  const theme = useTheme();

  React.useEffect(() => {
    if(addUserResult.isSuccess) {
      setSnackbarText("Add successful!");  
      refetchPlatformUsers();
      setSelectedUserId(addUserResult.data.id);
    }
    else if (addUserResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(addUserResult);
      setSnackbarText(`An error occurred while trying add the user - ${errorMessage}`);
      setUserSaveError(errorMessage);
    }     
  }, [addUserResult]);

  React.useEffect(() => {
    if(updateUserResult.isSuccess) {
      setSnackbarText("Update successful!");  
      refetchPlatformUsers();
    }
    else if (updateUserResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(updateUserResult);
      setSnackbarText(`An error occurred while trying update the user - ${errorMessage}`);
      setUserSaveError(errorMessage);
    }     
  }, [updateUserResult]);

  React.useEffect(() => {
    if(selectedUserId !== params.slug){
      setSelectedUserId(params.slug);
    }
  }, [])

  React.useEffect(() => {
    if(selectedUserId && selectedUserId !== selectedUser?.data?.id) {
      loadSelectedUser({id: selectedUserId});
    }
  }, [selectedUserId])

  
  /**
   * Handle the save event from the form
   * @param formData The data from the submitted form
   */
  const handleSave = (formData: IPlatformUserFormViewModel) => {
    if(selectedUserId) {
      doUpdateUser({
        id: selectedUserId,
        platformUser: {
          platformUserName: formData.platformUserName,
          externalIdentityProviderId: formData.externalIdentityProviderId
        }
      });
    } else {
      doAddUser({
        platformUser: {
          platformUserName: formData.platformUserName,
          externalIdentityProviderId: formData.externalIdentityProviderId
        }
      });
    }
}

  const handleCancel = () => {
    navigate("/platform-users");
  }


  /**
   * This is the main render function for this component
   */
    if (userSaveError) {
      return (
        <React.Fragment>
          {userSaveError && (
            <Container sx={{
              textAlign: "center" as "center",
              padding: "20px",
              color: theme.palette.error.main,
            }}>
              {userSaveError}
            </Container>
          )}
        </React.Fragment>
      );
    } else if (selectedUser.data) {
      return (
        <React.Fragment>
          <Paper sx={{width: "100%"}}>
            <Box sx={{
              display: "flex",
              flexDirection: "column" as "column",
              alignItems: "baseline",
              p: 2
            }}>
              <Typography
                variant="h5"
                sx={{mr: 1}}
                gutterBottom
              >
                Edit Platform User
              </Typography>
              <Typography variant="subtitle2">{selectedUserId}</Typography>
            </Box>
            <Divider />
            <PlatformUserForm
              isLoading={selectedUser.isLoading}
              isSaving={addUserResult.isLoading || updateUserResult.isLoading}
              user={selectedUser.data}
              error={userSaveError}
              onSave={(data: IPlatformUserFormViewModel) => handleSave(data)}
              onCancel={() => handleCancel()}
            />
          </Paper>
          <Toast
            open={snackbarText !== undefined}
            onClose={() => setSnackbarText(undefined)}
            text={snackbarText ?? ""}
          />
        </React.Fragment>
      );
    } else {
      return (
        <Container sx={{padding: "20px", textAlign: "center" }}>
          <CircularProgress />
        </Container>
      );
    }

  
}
