export class ScanProcessingConstants {
  /**
   * The names of the processing components per the component_name values in the components table of the database
   */
  static readonly stepSystemIds = {
    scanIngest: "IMPORT",
  };

  /**
   * The key used in the Attributes bucket in applications that holds the ROI classification method attribute
   */
  static readonly roiClassificationMethodApplicationAttributeKey = 'ROI_CLASSIFICATION_METHOD_ATTRIBUTE'

  /**
   * The roi classification attribute associated with Concordance applications
   */
  static readonly concordanceRoiClassificationValue = "CONCORDANCE"
}
