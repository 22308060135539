import { createSelector } from "@reduxjs/toolkit";
import { InterscanAnalysisViewModel, InterscanAnalysisViewModelPagedResult } from "../api/aiq-api";
import { enhancedApi } from "../api/enhancedApi";
import { UseQueryResult } from "../api/useQueryResult";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { ApplicationState } from "../../redux/store/ConfigureStore";

/**
 * This selector composes a set of Client-Side Scan Analysis View Models that have corresponding Steps
 * from the data in the state based on the Application/Study currently selected for Scan Processing
 */
export const SelectedStudyInterscanAnalysisVMsSelector = createSelector<any, Array<ClientSideInterscanAnalysisViewModel>>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdInterscanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.interscanAnalysisPageNumber,
    pageSize: state.scanAnalyses.interscanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.interscanAnalysisPatientFilter
  })(state as any)),
  (
    interscanAnalysisViewModelsResult: UseQueryResult<InterscanAnalysisViewModelPagedResult>,
  ): Array<ClientSideInterscanAnalysisViewModel> => {
    return ClientSideInterscanAnalysisVMSelectorMethods.GetClientSideInterscanAnalysisVMs(interscanAnalysisViewModelsResult.data?.result ?? []);
  }
);

/**
 * This selector composes a set of Client-Side Interscan Analysis View Models that have corresponding Steps
 * from the data in the state based on the currently selected Compound Interscan Analysis
 */
export const SelectedCompoundInterscanAnalysisInterscanVMsSelector = createSelector<any, Array<ClientSideInterscanAnalysisViewModel>>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdInterscanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.interscanAnalysisPageNumber,
    pageSize: state.scanAnalyses.interscanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.interscanAnalysisPatientFilter
  })(state as any)),
  (
    interscanAnalysisViewModelsResult: UseQueryResult<InterscanAnalysisViewModelPagedResult>,
  ): Array<ClientSideInterscanAnalysisViewModel> => {
    return ClientSideInterscanAnalysisVMSelectorMethods.GetClientSideInterscanAnalysisVMs(interscanAnalysisViewModelsResult.data?.result ?? []);
  }
);

/**
 * A selector returning a boolean indicating whether Scan Analyses for the currently selected Study are loading
 */
 export const SelectedStudyInterscanAnalysesLoadingSelector = createSelector<any, boolean>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdInterscanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.interscanAnalysisPageNumber,
    pageSize: state.scanAnalyses.interscanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.interscanAnalysisPatientFilter
  })(state as any)),
  (scanAnalyses: UseQueryResult<InterscanAnalysisViewModelPagedResult>) => scanAnalyses?.isLoading ?? false
);

/**
 * A selector returning the total count of the current set of Interscan Analysis view models
 */
 export const SelectedStudyInterscanAnalysesTotalCountSelector = createSelector<any, number>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdInterscanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.interscanAnalysisPageNumber,
    pageSize: state.scanAnalyses.interscanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.interscanAnalysisPatientFilter
  })(state as any)),
  (scanAnalyses: UseQueryResult<InterscanAnalysisViewModelPagedResult>) => scanAnalyses?.data?.totalCount ?? 0
);

/**
 * This selector returns a Client Side Interscan Analysis View Model corresponding to the currently selected Scan Analysis
 */
export const SelectedInterscanAnalysisVMSelector = createSelector<any, ClientSideInterscanAnalysisViewModel | undefined>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiInterscanAnalysisViewModelsById.select({ id: state.interscanAnalysis.edit.viewedInterscanAnalysisId!})(state as any)),
  (
    selectedScanAnalysisVMResult: UseQueryResult<InterscanAnalysisViewModel>,
  ): ClientSideInterscanAnalysisViewModel | undefined => 
  ClientSideInterscanAnalysisVMSelectorMethods.GetInterscanAnalysisVMByInterscanAnalysis(selectedScanAnalysisVMResult)
);

// These methods constitute the business logic used by the selectors. They are exported so they can be unit tested.
export const ClientSideInterscanAnalysisVMSelectorMethods = {
  GetInterscanAnalysisVMByInterscanAnalysis(
    selectedInterscanAnalysisVMResult: UseQueryResult<InterscanAnalysisViewModel>,
  ): ClientSideInterscanAnalysisViewModel | undefined {
    if(selectedInterscanAnalysisVMResult.data) {
        try {
            let newInterscanAnalysisVM =  new ClientSideInterscanAnalysisViewModel(selectedInterscanAnalysisVMResult.data!);
            return newInterscanAnalysisVM;
        } catch (error) {
          return undefined;            
        }
    }
    return undefined;
  },

  GetClientSideInterscanAnalysisVMs(
    interscanAnalysisViewModelsResult: Array<InterscanAnalysisViewModel>,
  ): Array<ClientSideInterscanAnalysisViewModel> {

    var result = new Array<ClientSideInterscanAnalysisViewModel>();
    interscanAnalysisViewModelsResult.forEach(backendScanAnalysisVM => {
        try {
            let newScanAnalysisVM = new ClientSideInterscanAnalysisViewModel(backendScanAnalysisVM);
            result.push(newScanAnalysisVM);
        } catch (error) {
            console.warn(error);
        }
    });

   return result;
  },
};
