import { Typography, Grid, Card, CircularProgress, Container, useTheme, Box, Menu, MenuItem } from "@mui/material";

import React from "react";
import { getFormattedDateFromString, dateFormatWithTime } from "../../app/utilities/dateUtilities";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { ScanProcessingStatusIndicator } from "./ScanProcessingStatusIndicator";
import { SingleScanProcessingCard } from "./SingleScanProcessingCard";
import { useNavigate } from "react-router-dom";
import { useDraggable } from "@dnd-kit/core";
import { NavigationConstants } from "../../constants/NavigationConstants";

interface IInterscanProcessingCardProps {
  interscanAnalysisVM: ClientSideInterscanAnalysisViewModel;
  isNested?: boolean;
}

/**
 * This component displays a card on the Interscan Processing dashboard representing
 * the state of processing on an Interscan Analysis
 */
export const InterscanProcessingCard: React.FunctionComponent<IInterscanProcessingCardProps> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: props.interscanAnalysisVM.interscanAnalysis.id!,
    disabled: props.isNested
  });

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const baseStyle = {
    my: "6px",
    mx: "3px",
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.secondary.dark,
    display: "flex"
  }

  const draggedStyle = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`
  } : {};

  const handleContextMenu = (event: React.MouseEvent) => {
    if(!props.isNested) {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
      );
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const openInNewTab = () => {
    setContextMenu(null);
    window.open(`${NavigationConstants.interscanAnalysisPath}/${props.interscanAnalysisVM.interscanAnalysis?.id}`, '_blank');
  }

  const openInCurrentTab = () => {
    !props.isNested && navigate(`${NavigationConstants.interscanAnalysisPath}/${props.interscanAnalysisVM.interscanAnalysis?.id}`);
  }

  const getStripeColor = (): string => {

    // Priority for stripe color: Error > Warning > Everything else
    if (props.interscanAnalysisVM.currentStepHasError) {
      return theme.palette.error.light;
    }

    if (props.interscanAnalysisVM.currentStepHasWarnings) {
      return theme.palette.warning.main;
    }

    if (props.interscanAnalysisVM.canMoveToNextStep) {
      return theme.palette.success.light;
    }

    if (props.interscanAnalysisVM.currentStepComplete) {
      return theme.palette.success.main;
    }

    return theme.palette.inProgress.main;
  };

  return (
    <div onContextMenu={handleContextMenu} >
      <Card data-cy="InterscanProcessingCard"
            sx={[baseStyle, draggedStyle]}
            onClick={() => contextMenu == null && openInCurrentTab()}
            ref={setNodeRef}
            {...listeners} {...attributes}
      >
        <Box data-cy="InterscanProcessingCardStatusStripe" sx={{ width: 4, backgroundColor: getStripeColor()}}></Box>
        <Grid container spacing={0} alignItems="flex-start">
          <Grid item xs={4}>
            <Typography variant="subtitle2" sx={{
                textAlign: "right",
                marginRight: "2px",
                fontSize: 12,
                color: theme.palette.primary.light
            }}>
              Study:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{fontSize: 12}}>
              {props.interscanAnalysisVM.study.studyName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" sx={{
                textAlign: "right",
                marginRight: "2px",
                fontSize: 12,
                color: theme.palette.primary.light
            }}>
              Created:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{fontSize: 12}}>
              {getFormattedDateFromString(props.interscanAnalysisVM.interscanAnalysis.initiatedDate, dateFormatWithTime)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {props.interscanAnalysisVM.sourceScanAnalysisGroups.map((group, groupIndex) => (
              <Box
              key = {groupIndex}
              sx={{
                p: props.interscanAnalysisVM.isGrouped ? .3 : 0,
                my: props.interscanAnalysisVM.isGrouped ? 1 : 0,
                mx: props.interscanAnalysisVM.isGrouped ? .5 : 0,
                background: props.interscanAnalysisVM.isGrouped ? (theme) => theme.palette.secondary.light : "inherit"
              }}>
              {group.sort((a, b) => new Date(a.exam.examDate!).getTime() - new Date(b.exam.examDate!).getTime())
              .map((scanAnalysisVM) => (
                <SingleScanProcessingCard
                  key={scanAnalysisVM.scanAnalysis.id!}
                  scanAnalysisVM={scanAnalysisVM}
                  compact
                  isNested
                />
              ))}
              </Box>
              ))}
            {props.interscanAnalysisVM.sourceScanAnalysisGroups.filter(group => group.length > 0).length === 0 && (
              <Container style={{ textAlign: "center" }}>
                <CircularProgress color="inherit" size={20} />
              </Container>
            )}
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2" sx={{
              fontSize: 8,
              color: theme.palette.secondary.dark,
              padding: "2px"
            }}>
              {props.interscanAnalysisVM.interscanAnalysis.id}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <ScanProcessingStatusIndicator scanAnalysisVM={props.interscanAnalysisVM} />
          </Grid>
        </Grid>
      </Card>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={openInNewTab}>Open In New Tab</MenuItem>
      </Menu>
    </div>
  );
};
