import * as React from "react";

import { Study, StudyStatus } from "../../app/api/aiq-api";
import { IStudyFormViewModel } from "./StudyForm";
import {
  Container,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  Grid,
  Box,
} from "@mui/material";

interface IStudyInfoProps {
  // The pieces of state we will use
  saving: boolean;
  loading: boolean;
  study: Study;
  usedStudyNames: Array<string>;
  studyStatuses: Array<StudyStatus>;
  onSave: (formData: IStudyFormViewModel) => void;
}

/**
 * This interface defines the shape of state information strictly local to this component
 * and not part of the redux store's state.
 */
interface IStudyInfoState {
}

export class StudyInfoComponent extends React.PureComponent<
  IStudyInfoProps,
  IStudyInfoState
> {
  constructor(props: IStudyInfoProps) {
    super(props);
  }



  /**
   * This is the main render function for this component
   */
  public render() {
    if (this.props.study && !this.props.loading) {
      return (
        <React.Fragment>
          <Paper sx={{width: "100%", marginBottom: "10px"}}>
            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
              <Grid item xs={12}>
                <Box sx={{display: "flex",
                  flexDirection: "column" as "column",
                  alignItems: "baseline",
                  p: 2}}>
                  <Typography
                    variant="h5"
                    sx={{ mr: 1}}
                    gutterBottom
                  >
                    Study ID
                  </Typography>
                  <Typography variant="subtitle2">{this.props.study.id}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{display: "flex",
                  flexDirection: "column" as "column",
                  alignItems: "baseline",
                  p: 2}}>
                  <Typography
                    variant="h5"
                    sx={{ mr: 1}}
                    gutterBottom
                  >
                    Study Name
                  </Typography>
                  <Typography variant="subtitle2">{this.props.study.studyName}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Paper>
        </React.Fragment>
      );
    } else {
      return (
        <Container sx={{padding: "20px", textAlign: "center"}}>
          <CircularProgress />
        </Container>
      );
    }
  }
}

/**
 * This component provides UI to display and edit basic Study info
 */
export const StudyInfo = StudyInfoComponent;
