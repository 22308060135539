import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  TableContainer,
  Paper,
  CircularProgress,
  Typography,
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";
import { Add } from "@mui/icons-material/";
import { StudyForm, IStudyFormViewModel } from "./StudyForm";
import { Toast } from "../common/Toast";
import { StudyList } from "./StudyList";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { NavigationConstants } from "../../constants/NavigationConstants";
import { Study } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { UsedStudyNamesLowerCase } from "../../app/selectors/studySelectors";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import { useNavigate } from "react-router";
import { setSelectedStudy } from "../../app/slices/scanAnalysisSlice";

/**
 * This is a connected component that displays a list of Platform Studies
 */
export const Studies: React.FunctionComponent = () => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState<boolean>(false);
  const [studyToDelete, setStudyToDelete] = React.useState<Study | undefined>(undefined);
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarText, setSnackbarText] = React.useState<string>("");
  const [newStudyDialogOpen, setNewStudyDialogOpen] = React.useState<boolean>(false);
  const [doAddStudy, addStudyResult] = enhancedApi.endpoints.postApiStudies.useMutation();
  const [doUpdateStudy, updateStudyResult] = enhancedApi.endpoints.putApiStudiesById.useMutation();
  const [doDeleteStudy, deleteStudyResult] = enhancedApi.endpoints.deleteApiStudiesById.useMutation();
  const { data: studies, isLoading: studiesLoading } = enhancedApi.endpoints.getApiStudies.useQuery();
  const { data: studyStatuses, isLoading: studyStatusesLoading } = enhancedApi.endpoints.getApiStudyStatuses.useQuery();
  const usedStudyNames = useSelector((state: ApplicationState) => UsedStudyNamesLowerCase(state as never))
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSaveNewStudy = async (studyFormData: IStudyFormViewModel) => {
    if (studyFormData.id) {
      var studyPayload = 
      await doUpdateStudy({
        id: studyFormData.id,
        study: {
          id: studyFormData.id,
          principalInvestigator: studyFormData.principalInvestigator,
          startDate: studyFormData.startDate.toISOString(),
          statusId: studyFormData.statusId,
          studyName: studyFormData.studyName,
        } as Study,
      });
      if (updateStudyResult.isError) {
        setSnackbarText(`An error occurred while trying to save the Study`);
      } else {
        setSnackbarText("Study saved!");
      }
    } else {
      await doAddStudy({
        study: {
          principalInvestigator: studyFormData.principalInvestigator,
          startDate: studyFormData.startDate.toISOString(),
          statusId: studyFormData.statusId,
          studyName: studyFormData.studyName,
        } as Study,
      });
      if (addStudyResult.error) {
        setSnackbarText(`An error occurred while trying to save the Study`);
      } else {
        setSnackbarText("Study saved!");
      }
    }
    setNewStudyDialogOpen(false);

    setSnackbarOpen(true);
  };

  const handleDeleteStudy = async (studyId: string) => {
    var studyToDelete = studies?.find((study) => study.id === studyId);
    setStudyToDelete(studyToDelete);
    setDeleteConfirmationOpen(true);
  };

  const handleGoToStudyProcessing = (studyId: string) => {
    dispatch(setSelectedStudy(studyId));
    navigate(`${NavigationConstants.singleScanProcessingPath}?${NavigationConstants.scanProcessingStudyIdQueryStringKey}=${studyId}`);
  };

  const handleDeleteConfirmationCancel = () => {
    setStudyToDelete(undefined);
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteConfirmationConfirm = async () => {
    if (studyToDelete) {
      await doDeleteStudy({ id: studyToDelete.id! });
      if (deleteStudyResult.error) {
        setSnackbarText(`An error occurred while trying to delete Study ${studyToDelete.studyName}`);
      } else {
        setSnackbarText("Delete successful");
      }
      setSnackbarOpen(true);
    }
    setStudyToDelete(undefined);
    setDeleteConfirmationOpen(false);
  };

  const renderStudiesPageHeader = () => {
    return (
      <Box id="tableLabel" 
        sx={{
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "space-between",
          width: "100%"
      }}>
        <Typography variant="h4">Platform Studies</Typography>
        <div>
          <Button
            data-cy="AddStudyButton"
            variant="contained"
            onClick={() => setNewStudyDialogOpen(true)}
            color="primary"
            style={{ margin: "10px" }}
          >
            <Add /> Add
          </Button>
        </div>
      </Box>
    );
  };

  const renderNewStudyDialog = () => {
    return (
      <Dialog
        data-cy="NewStudyDialog"
        open={newStudyDialogOpen}
        onClose={() => setNewStudyDialogOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogContent>
          <StudyForm
            isSaving={false}
            readOnly={false}
            study={{} as Study}
            isNewStudy={true}
            onSave={(studyFormData: IStudyFormViewModel) => handleSaveNewStudy(studyFormData)}
            existingStudyNames={usedStudyNames}
            studyStatuses={studyStatuses ?? []}
            onCancel={() => setNewStudyDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const renderStudiesTable = () => {
    return (
      <TableContainer component={Paper} sx={{maxHeight: "100%"}}>
        {(studiesLoading || studyStatusesLoading) ? (
          <Container sx={{padding: "20px", textAlign: "center"}}>
            <CircularProgress />
          </Container>
        ) : (
            <React.Fragment>
              <StudyList
                studies={studies ?? []}
                studyDeleteClicked={(studyId: string) => handleDeleteStudy(studyId)}
                studyProcessingClicked={(studyId: string) => handleGoToStudyProcessing(studyId)}
              />
            </React.Fragment>
        )}
      </TableContainer>
    );
  };

  const renderDeleteConfirmation = () => {
    return (
      <ConfirmationDialog
        open={deleteConfirmationOpen}
        title="Delete Study?"
        confirmButtonLabel="Delete"
        text={`Are you sure you want to delete study ${
          studyToDelete ? studyToDelete.studyName : "UNKNOWN"
        } ?`}
        onCancel={() => handleDeleteConfirmationCancel()}
        onConfirm={() => handleDeleteConfirmationConfirm()}
      />
    );
  };

  return (
    <div data-cy="StudyManagement" style={{width: "100%"}}>
      {renderStudiesPageHeader()}
      {renderStudiesTable()}
      {renderNewStudyDialog()}
      {renderDeleteConfirmation()}

      <Toast
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        text={snackbarText}
      />
    </div>
  );
};


