import * as React from "react";
import {
  Box,
  Checkbox, MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { IClientSideRoiViewModel } from "../../redux/services/view-models/ClientSideRoiViewModel";
import { getRoiLocationsForReporting } from "../../constants/RoiLocationConstants";
import { AiqTooltip } from "../common/AiqToolTip";
import { Help } from "@mui/icons-material";

interface IRoiDetailsTableProps {
  rois: Array<IClientSideRoiViewModel>;
  showSlbScore: boolean;
  onRoiSelected: (roi: IClientSideRoiViewModel) => void;
  onAllRoisSelected: (isSelected: boolean) => void;
  onRoiLocationChanged: (roi: IClientSideRoiViewModel, location: string) => void;
  isReadOnly: boolean;
}

export const RoiDetailsTable: React.FunctionComponent<IRoiDetailsTableProps> = (props) => {

  const defaultColumnMinWidth = 100;
  const slbCutoff = 0.5;

  const roiCount = props.rois.length;
  const selectedRoiCount = props.rois.filter(x => x.isSelected).length;

  const roiLocationChoices: Array<string> = getRoiLocationsForReporting();

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: 420 }}
    >
      <Table
        stickyHeader
        size="small"
        aria-label="a dense table"
        sx={{ height: "max-content" }}
      >
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={selectedRoiCount > 0 && selectedRoiCount < roiCount}
                checked={roiCount > 0 && selectedRoiCount === roiCount}
                onChange={(e) => props.onAllRoisSelected(e.target.checked)}
              />
            </TableCell>
            <TableCell align="right" sx={{ minWidth: defaultColumnMinWidth }}>ROI ID</TableCell>
            <TableCell align="left" sx={{ minWidth: defaultColumnMinWidth }}>ROI Location</TableCell>
            <TableCell align="right" sx={{ minWidth: defaultColumnMinWidth }}>SUV Max</TableCell>
            <TableCell align="right" sx={{ minWidth: defaultColumnMinWidth }}>SUV Mean</TableCell>
            <TableCell align="right" sx={{ minWidth: defaultColumnMinWidth }}>SUV Total</TableCell>
            <TableCell align="right" sx={{ minWidth: defaultColumnMinWidth }}>Volume</TableCell>
            <TableCell align="right" sx={{ minWidth: defaultColumnMinWidth }}>SUV STD</TableCell>

            {
              props.showSlbScore
              ? <TableCell align="right" sx={{ minWidth: defaultColumnMinWidth }}>SLB Score</TableCell>
              : null
            }

            <TableCell align="right" sx={{ minWidth: defaultColumnMinWidth }}>Deleted</TableCell>
            <TableCell sx={{ minWidth: 150 }}>Reason for Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rois.map((roi: IClientSideRoiViewModel) => (
            <TableRow
              key={roi.roiId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              hover={true}
              selected={roi.isSelected}
              onClick={() => props.onRoiSelected(roi)}
              ref={(e) => {
                if (e && roi.isSelected) {
                  e.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start"
                  });
                }
              }}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={roi.isSelected}
                />
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {roi.roiId}
              </TableCell>
              <TableCell
                onClick={(e) => e.stopPropagation()}
              >
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                }}>
                  <TextField
                    id="select-roi-location"
                    select
                    value={roi.anatomySegmentSysName}
                    size="small"
                    fullWidth
                    onChange={(e) => props.onRoiLocationChanged(roi, e.target.value)}
                    disabled={props.isReadOnly}
                  >
                    {roiLocationChoices.map((location: string) => (
                      <MenuItem key={location} value={location} >
                        <Typography style={{ textTransform: "capitalize", fontSize: 13, padding: 0, margin: 0 }}>
                          {location.replaceAll("_", " ").toLocaleLowerCase()}
                        </Typography>
                      </MenuItem>
                    ))}
                  </TextField>
                  {props.isReadOnly ? null :
                    <AiqTooltip
                      title={`System-Assigned Location: ${roi.anatomySegmentSysName}`}
                      placement="right"
                    >
                      <Help
                        fontSize="small"
                        color="disabled"
                        sx={{fontSize: "1.2em", marginLeft: "4px"}}
                      />
                    </AiqTooltip>
                  }
                </Box>
              </TableCell>
              <TableCell align="right">
                {roi.roiMax?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? ""}
              </TableCell>
              <TableCell align="right">
                {roi.roiMean?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? ""}
              </TableCell>
              <TableCell align="right">
                {roi.roiTotal?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? ""}
              </TableCell>
              <TableCell align="right">
                {roi.roiVolume?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? ""}
              </TableCell>
              <TableCell align="right">
                {roi.roiStd?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? ""}
              </TableCell>

              {/* Apply conditional formatting to SLB Score cell */}
              {
                props.showSlbScore
                  ? ((roi.slbScore ?? 0) >= slbCutoff)
                    ? <TableCell align="right" style={{ backgroundColor: "yellow" }}>
                      {roi.slbScore?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? ""}
                    </TableCell>
                    : <TableCell align="right">
                      {roi.slbScore?.toLocaleString('en-US', { maximumFractionDigits: 2 }) ?? ""}
                    </TableCell>
                  : null
              }

              <TableCell align="right">{roi.isDeleted.toString()}</TableCell>
              <TableCell>{roi.deleteReason ?? ""}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
