import * as React from "react";
import { Container, Paper, Backdrop, CircularProgress, useTheme } from "@mui/material";
import { CompoundInterscanProcessingLane } from "./CompoundInterscanProcessingLane";
import { ScanProcessingConstants } from "../../../constants/ScanProcessingConstants";
import { Toast } from "../../common/Toast";
import { CompoundInterscanAnalysisViewModel, CompoundInterscanApplication } from "../../../app/api/aiq-api";
import { useSelector } from "react-redux";
import { enhancedApi } from "../../../app/api/enhancedApi";
import { getErrorMessageFromRtkMutationResult } from "../../../app/api/apiUtilities";
import { SelectedStudyCompoundInterscanAnalysisVMsSelector } from "../../../app/selectors/compoundInterscanAnalysisSelectors";
import { ClientSideCompoundInterscanAnalysisViewModel } from "../../../redux/services/view-models/ClientSideCompoundInterscanAnalysisViewModel";
import { ClientSideCompoundInterscanAnalysisStepViewModel } from "../../../redux/services/view-models/ClientSideCompoundInterscanAnalysisStepViewModel";
import {DndContext, DragStartEvent, DragEndEvent, useSensors, PointerSensor, useSensor} from '@dnd-kit/core';

// Derive the AppProps type info
interface ICompoundInterscanProcessingLanesProps {
  application: CompoundInterscanApplication,
  onStepTransition: () => void
}

/**
 * This is a connected component that displays a series of lanes representing a step in compound interscan analysis
 */
export const CompoundInterscanProcessingLanes: React.FunctionComponent<ICompoundInterscanProcessingLanesProps> = (props) => {
  const [draggedScanAnalysis, setDraggedScanAnalysis] = React.useState<ClientSideCompoundInterscanAnalysisViewModel | undefined>(undefined);
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [doTransition, transitionResult] = enhancedApi.endpoints.postApiScanAnalysisStepsByIdTransition.useMutation();
  const compoundInterscanAnalysisVMs = useSelector(SelectedStudyCompoundInterscanAnalysisVMsSelector);
  const theme = useTheme();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )

  const scanAnalysisStepById = (stepId: string): ClientSideCompoundInterscanAnalysisStepViewModel | undefined => {
    var allSteps =  compoundInterscanAnalysisVMs.flatMap(savm => savm.orderedScanAnalysisSteps);
    return allSteps.find(step => step.scanAnalysisStep.id === stepId)
  }

  React.useEffect(() => {
    var targetStepTemplateSystemId = transitionResult.originalArgs?.scanAnalysisStepTransitionRequest.targetStepSystemId ?? "Unknown";
    var originStepId = transitionResult.originalArgs?.scanAnalysisStepTransitionRequest.currentStepId ?? "Unknown";
    var originStepVM = scanAnalysisStepById(originStepId);
    var targetStepVM = originStepVM?.parentCompoundInterscanAnalysisViewModel?.applicationTemplate?.steps?.find(step => step.systemId === targetStepTemplateSystemId);
    if(transitionResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(transitionResult.error);
      setSnackbarText(
        `FAILED to Transition Compound Interscan Analysis ${originStepVM!.parentCompoundInterscanAnalysisViewModel.compoundInterscanAnalysis.id} from 
        ${ originStepVM?.stepTemplate.displayName } to ${targetStepVM?.displayName} - ${errorMessage}`
      );  
    } else if(transitionResult.isSuccess ) {
      setSnackbarText(
        `Transitioned Compound Interscan Analysis ${originStepVM!.parentCompoundInterscanAnalysisViewModel.compoundInterscanAnalysis.id} from 
        ${ originStepVM?.stepTemplate.displayName } to ${targetStepVM?.displayName}`
      );
      props.onStepTransition();
    }
  }, [transitionResult])

  const onDragStart = (start: DragStartEvent) => {
    let draggedCard = compoundInterscanAnalysisVMs?.find(
      (card: CompoundInterscanAnalysisViewModel) => card.compoundInterscanAnalysis?.id === start.active.id
    );
    setDraggedScanAnalysis(draggedCard);
  }

  const onDragEnd = (event: DragEndEvent) => {
    let destinationStep = props.application.steps?.find(
      (step) => step.systemId === event.over?.id
    );
    if (destinationStep) {
      let currentStep = draggedScanAnalysis!.currentStep;
      if (draggedScanAnalysis && draggedScanAnalysis.canTransitionToStep(destinationStep.systemId!)) {
        try {
          var currentStepId: string = currentStep.scanAnalysisStep.id!

          var transitionRequest = {
            currentStepId: currentStepId,
            targetStepSystemId: destinationStep.systemId!,
          };
          doTransition({ id: currentStepId, scanAnalysisStepTransitionRequest: transitionRequest })
        } catch (error) {
          let errMesg = `Failed to transition Compound Interscan Analysis ${
            draggedScanAnalysis!.compoundInterscanAnalysis.id
          } from ${currentStep.stepTemplate.displayName} to ${destinationStep.displayName}: ${error instanceof Error ? error.message : "unknown"}`;
          setSnackbarText(errMesg);
          console.error(errMesg);
        }
      } else {
        console.error(`Failed to identify destination component`);
      }
    }
    setDraggedScanAnalysis(undefined);
    return;
  }

  const renderLanes = () => {
    return (
      <React.Fragment>
        {<Backdrop
          sx={{ zIndex: theme.zIndex.drawer + 1, color: "#fff"}}
          open={transitionResult.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>}
        <DndContext sensors={sensors} onDragEnd={(event) => onDragEnd(event)} onDragStart={(event) => onDragStart(event)}>

          <div style={{ display: "inline-block"}}>
            <Paper sx={{
              display: "flex",
              marginBottom: "20px",
              justifyContent: "flex-start"
            }}
            data-cy="CompoundInterscanProcessingLanes">
              {props.application.steps?.map((step) => (step.systemId !== ScanProcessingConstants.stepSystemIds.scanIngest && (
                <Container
                  key={step.systemId}
                  disableGutters={true}
                  sx={{
                    width: 275,
                    display: "flex",
                    margin: 0
                  }}
                >
                  <CompoundInterscanProcessingLane
                    stepTemplate={step}
                    draggedScanAnalysis={draggedScanAnalysis}
                    compoundInterscanAnalysisVMs={compoundInterscanAnalysisVMs?.filter((card) =>
                      card.applicationTemplate.systemId === props.application.systemId &&
                      card.currentStep.stepTemplate.systemId === step.systemId
                      )}
                  />
                </Container>)
              ))}
            </Paper>
          </div>
        </DndContext>
      </React.Fragment>
    );
  }
    return (
      <React.Fragment>
        {props.application && renderLanes()}
          <Toast
            open={snackbarText !== undefined}
            onClose={() => setSnackbarText(undefined)}
            text={snackbarText ?? ""}
            autoHideDurationMillisecs={6000}
          />
      </React.Fragment>
    );


}


