import { createSelector } from "@reduxjs/toolkit"
import { SingleScanApplication, InterscanApplication, CompoundInterscanApplication } from "../api/aiq-api";
import { SelectedStudySingleScanAnalysisVMsSelector } from "./singleScanAnalysisSelectors";
import { ClientSideSingleScanAnalysisViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import { enhancedApi } from "../api/enhancedApi";
import { UseQueryResult } from "../api/useQueryResult";
import { ApplicationState } from "../../redux/store/ConfigureStore";

const emptyApplications = new Array<SingleScanApplication>();

/**
 * A selector returning all currently loaded Single Scan Applications
 */
 export const AllSingleScanApplicationsSelector = createSelector<any, Array<SingleScanApplication>>(
  enhancedApi.endpoints.getApiSingleScanApplications.select(),
  (applicationsResult: UseQueryResult<Array<SingleScanApplication>>) => GetSortedApplications(applicationsResult?.data ?? emptyApplications));


/**
 * A selector returning the Scan Analysis Application templates belonging to the Interscan Analyses for the currently selected Study
 */
export const SingleScanApplicationsByStudyScanAnalyses = createSelector<any, Array<SingleScanApplication>>(
  (state: ApplicationState) => SelectedStudySingleScanAnalysisVMsSelector(state as never),
  (scanAnalysesBySelectedStudy: Array<ClientSideSingleScanAnalysisViewModel>): 
  Array<SingleScanApplication>  => {
    var allApplications = scanAnalysesBySelectedStudy.map(sa => sa.applicationTemplate);
    var dedupedApplications = GetSortedApplications(Array.from(new Set(allApplications)));
    return dedupedApplications;
  }
);

/**
 * A selector returning a boolean indicating whether Single Scan Applications are currently loading
 */
 export const SingleScanApplicationsLoadingSelector = createSelector<any, boolean>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiSingleScanApplications.select()(state)),
  (applicationsResult: UseQueryResult<SingleScanApplication>) => applicationsResult?.isLoading ?? false
);


/**
 * A selector returning all currently loaded Interscan Applications
 */
 export const AllInterscanApplicationsSelector = createSelector<any, Array<InterscanApplication>>(
  (state: ApplicationState) =>enhancedApi.endpoints.getApiInterscanApplications.select()(state as any),
  (applicationsResult: UseQueryResult<Array<InterscanApplication>>) => GetSortedApplications(applicationsResult?.data ?? emptyApplications)
);

/**
 * A selector returning all currently loaded Compound Interscan Applications
 */
export const AllCompoundInterscanApplicationsSelector = createSelector<any, Array<CompoundInterscanApplication>>(
  (state: ApplicationState) =>enhancedApi.endpoints.getApiCompoundInterscanApplications.select()(state as any),
  (applicationsResult: UseQueryResult<Array<CompoundInterscanApplication>>) => GetSortedApplications(applicationsResult?.data ?? emptyApplications)
);


function removeDuplicatesByField<T>(list: T[], fieldName: keyof T): T[] {
  const seenValues = new Set();
  return list.filter(item => {
    const fieldValue = item[fieldName];
    return seenValues.has(fieldValue) ? false : seenValues.add(fieldValue);
  });
}

function GetSortedApplications(applications: Array<InterscanApplication | SingleScanApplication | CompoundInterscanApplication> | null | undefined): Array<CompoundInterscanApplication | InterscanApplication | SingleScanApplication> {
  var result = [...applications ?? []].sort((a: SingleScanApplication, b: SingleScanApplication)=>  {
    // anything null gets sorted to the end
    if(!a.displaySortIndex) return 1;
    if(!b.displaySortIndex) return -1;

    // otherwise sort normally
    return a.displaySortIndex - b.displaySortIndex
  })
  return removeDuplicatesByField(result, "systemId");
}