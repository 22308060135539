import { Typography, Grid, Card, useTheme, Box,  Menu, MenuItem } from "@mui/material";

import React from "react";
import { getFormattedDateFromString, dateFormatWithTime } from "../../../app/utilities/dateUtilities";
import { ScanProcessingStatusIndicator } from "../ScanProcessingStatusIndicator";
import { ClientSideCompoundInterscanAnalysisViewModel } from "../../../redux/services/view-models/ClientSideCompoundInterscanAnalysisViewModel";
import { InterscanProcessingCard } from "../InterscanProcessingCard";

interface ICompoundInterscanProcessingCardProps {
  compoundInterscanAnalysisVM: ClientSideCompoundInterscanAnalysisViewModel;
}
import { useNavigate } from "react-router-dom";
import { useDraggable } from "@dnd-kit/core";
import { NavigationConstants } from "../../../constants/NavigationConstants";

/**
 * This component displays a card on the Interscan Processing dashboard representing
 * the state of processing on an Interscan Analysis
 */
export const CompoundInterscanProcessingCard: React.FunctionComponent<ICompoundInterscanProcessingCardProps> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: props.compoundInterscanAnalysisVM.compoundInterscanAnalysis.id!
  });

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const baseStyle = {
    my: "6px",
    mx: "3px",
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.secondary.dark,
    display: "flex"
  }

  const draggedStyle = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`
  } : {};

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const openInNewTab = () => {
    setContextMenu(null);
    window.open(`${NavigationConstants.compoundInterscanAnalysisPath}/${props.compoundInterscanAnalysisVM.compoundInterscanAnalysis?.id}`, '_blank');
  }

  const openInCurrentTab = () => {
    navigate(`${NavigationConstants.compoundInterscanAnalysisPath}/${props.compoundInterscanAnalysisVM.compoundInterscanAnalysis?.id}`);
  }

  const getStripeColor = (): string => {

    // Priority for stripe color: Error > Warning > Everything else
    if (props.compoundInterscanAnalysisVM.currentStepHasError) {
      return theme.palette.error.light;
    }

    if (props.compoundInterscanAnalysisVM.currentStepHasWarnings) {
      return theme.palette.warning.main;
    }

    if (props.compoundInterscanAnalysisVM.canMoveToNextStep) {
      return theme.palette.success.light;
    }

    if (props.compoundInterscanAnalysisVM.currentStepComplete) {
      return theme.palette.success.main;
    }

    return theme.palette.inProgress.main;
  };

  return (
    <div onContextMenu={handleContextMenu}>
      <Card data-cy="CompoundInterscanProcessingCard"
            sx={[baseStyle, draggedStyle]}
            onClick={() => contextMenu == null && openInCurrentTab()}
            ref={setNodeRef}
            {...listeners} {...attributes}
      >
        <Box data-cy="CompoundInterscanProcessingCardStatusStripe"
             sx={{ width: 4, backgroundColor: getStripeColor() }}></Box>
        <Grid container spacing={0} alignItems="flex-start">
          <Grid item xs={4}>
            <Typography variant="subtitle2" sx={{
              textAlign: "right",
              marginRight: "2px",
              fontSize: 12,
              color: theme.palette.primary.light
            }}>
              Study:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
              {props.compoundInterscanAnalysisVM.study.studyName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" sx={{
              textAlign: "right",
              marginRight: "2px",
              fontSize: 12,
              color: theme.palette.primary.light
            }}>
              Created:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
              {getFormattedDateFromString(props.compoundInterscanAnalysisVM.compoundInterscanAnalysis.initiatedDate, dateFormatWithTime)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {props.compoundInterscanAnalysisVM.sourceInterscanAnalyses.map((interscanAnalysisVM) => (
              <Box
                key={interscanAnalysisVM.interscanAnalysis.id!}
                sx={{
                  p: 0,
                  my: 0,
                  mx: 0,
                  background: "inherit"
                }}>
                <InterscanProcessingCard interscanAnalysisVM={interscanAnalysisVM} isNested />
              </Box>
            ))}
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2" sx={{
              fontSize: 8,
              color: theme.palette.secondary.dark,
              padding: "2px"
            }}>
              {props.compoundInterscanAnalysisVM.compoundInterscanAnalysis.id}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <ScanProcessingStatusIndicator scanAnalysisVM={props.compoundInterscanAnalysisVM} />
          </Grid>
        </Grid>
      </Card>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={openInNewTab}>Open In New Tab</MenuItem>
      </Menu>
    </div>
  );
};
