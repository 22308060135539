import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Paper, Backdrop, CircularProgress, useTheme } from "@mui/material";
import { SingleScanProcessingLane } from "./SingleScanProcessingLane";
import { ScanProcessingConstants } from "../../constants/ScanProcessingConstants";
import { Toast } from "../common/Toast";
import { NavigationConstants } from "../../constants/NavigationConstants";
import { ScanAnalysisViewModel, SingleScanApplication } from "../../app/api/aiq-api";
import { ClientSideSingleScanAnalysisViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import { enhancedApi } from "../../app/api/enhancedApi";
import { useSelector } from "react-redux";
import { SelectedStudySingleScanAnalysisVMsSelector } from "../../app/selectors/singleScanAnalysisSelectors";
import { getErrorMessageFromRtkMutationResult } from "../../app/api/apiUtilities";
import { ClientSideSingleScanAnalysisStepViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisStepViewModel";
import {DndContext, DragStartEvent, DragEndEvent, useSensors, PointerSensor, useSensor} from '@dnd-kit/core';

// Derive the AppProps type info
interface ISingleScanProcessingLanesProps {
  application: SingleScanApplication,
  onStepTransition: () => void
}

/**
 * This is a connected component that displays a series of lanes representing a step in single scan analysis
 */
export const SingleScanProcessingLanes: React.FunctionComponent<ISingleScanProcessingLanesProps> = (props) => {
  const [draggedScanAnalysis, setDraggedScanAnalysis] = React.useState<ClientSideSingleScanAnalysisViewModel | undefined>(undefined);
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [snackbarText, setSnackbarText] = React.useState<string>("");
  const [doTransition, transitionResult] = enhancedApi.endpoints.postApiScanAnalysisStepsByIdTransition.useMutation();
  const scanAnalysisVMs = useSelector(SelectedStudySingleScanAnalysisVMsSelector);

  const navigate = useNavigate();
  const theme = useTheme();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )

  const showToast = (toastText: string) => {
    setSnackbarText(toastText);
    setSnackbarOpen(true);
  }

  const scanAnalysisStepById = (stepId: string): ClientSideSingleScanAnalysisStepViewModel | undefined => {
    var allSteps =  scanAnalysisVMs.flatMap(savm => savm.orderedScanAnalysisSteps);
    return allSteps.find(step => step.scanAnalysisStep.id === stepId)
  }

  React.useEffect(() => {
    var targetStepTemplateSystemId = transitionResult.originalArgs?.scanAnalysisStepTransitionRequest.targetStepSystemId ?? "Unknown";
    var originStepId = transitionResult.originalArgs?.scanAnalysisStepTransitionRequest.currentStepId ?? "Unknown";
    var originStepVM = scanAnalysisStepById(originStepId);
    var targetStepVM = originStepVM?.parentSingleScanAnalysisViewModel?.applicationTemplate?.steps?.find(step => step.systemId === targetStepTemplateSystemId);
    if(transitionResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(transitionResult.error);
      showToast(
        `FAILED to Transition Scan Analysis ${originStepVM!.parentSingleScanAnalysisViewModel.patient?.patientIdentifier} - ${originStepVM!.parentSingleScanAnalysisViewModel.timepoint.timepointName} from 
        ${ originStepVM?.stepTemplate.displayName } to ${targetStepVM?.displayName} - ${errorMessage}`
      );  
    } else if(transitionResult.isSuccess ) {
      showToast(
        `Transitioned Scan Analysis ${originStepVM!.parentSingleScanAnalysisViewModel.patient?.patientIdentifier} - ${originStepVM!.parentSingleScanAnalysisViewModel.timepoint.timepointName} from 
        ${ originStepVM?.stepTemplate.displayName } to ${targetStepVM?.displayName}`
      );
      props.onStepTransition();
    }
  }, [transitionResult])

  const onDragStart = (start: DragStartEvent) => {
    var draggedCard = scanAnalysisVMs?.find(
      (card: ScanAnalysisViewModel) => card.scanAnalysis?.id === start.active.id
    );
    setDraggedScanAnalysis(draggedCard);
  }

  const onDragEnd = (event: DragEndEvent) => {
    let destinationStep = props.application.steps?.find(
      (step) => step.systemId === event.over?.id
    );
    if (destinationStep) {
      let currentStep = draggedScanAnalysis!.currentStep;
      if(draggedScanAnalysis && draggedScanAnalysis.canTransitionToStep(destinationStep.systemId!)) {
        try {
          let currentStepId: string = currentStep.scanAnalysisStep.id!

          let transitionRequest = {
            currentStepId: currentStepId,
            targetStepSystemId: destinationStep.systemId!,
          };
          doTransition({ id: currentStepId, scanAnalysisStepTransitionRequest: transitionRequest })
        } catch (error) {
          let errMesg = `Failed to transition Scan Analysis ${
            draggedScanAnalysis!.scanAnalysis.id
          } from ${currentStep.stepTemplate.displayName} to ${destinationStep.displayName}: ${error instanceof Error ? error.message : "unknown"}`;
          showToast(errMesg);
          console.error(errMesg);
        }
      }
    } else {
      console.error(`Failed to identify destination component`);
    }
    setDraggedScanAnalysis(undefined);
    return;
  }

  const renderLanes = () => {
    return (
      <React.Fragment>
        <Backdrop
          sx={{ zIndex: theme.zIndex.drawer + 1, color: "#fff"}}
          open={transitionResult.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DndContext sensors={sensors} onDragEnd={(event) => onDragEnd(event)} onDragStart={(event) => onDragStart(event)}>

          <div style={{ display: "inline-block"}}>
          <Paper sx={{
              display: "flex",
              marginBottom: "20px",
              justifyContent: "flex-start"
            }}>
              {props.application.steps?.map((step) => (step.systemId !== ScanProcessingConstants.stepSystemIds.scanIngest && (
                <Container
                  key={step.systemId}
                  disableGutters={true}
                  sx={{
                    width: 275,
                    display: "flex",
                    margin: 0
                  }}
                >
                  <SingleScanProcessingLane
                    stepTemplate={step}
                    draggedScanAnalysis={draggedScanAnalysis}
                    scanAnalysisVMs={scanAnalysisVMs?.filter((card) =>
                      card.applicationTemplate.systemId === props.application.systemId &&
                      card.currentStep.stepTemplate.systemId === step.systemId
                      )}
                  />
                </Container>)
              ))}
            </Paper>
          </div>
        </DndContext>
      </React.Fragment>
    );
  }
    return (
      <React.Fragment>
        {props.application && renderLanes()}
          <Toast
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            text={snackbarText}
            autoHideDurationMillisecs={6000}
          />
      </React.Fragment>
    );


}


