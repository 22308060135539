import * as React from "react";
import {
  Typography,
  Grid,
  Paper,
  Divider,
  Button,
  Box,
  CircularProgress,
  List,
  ListItem
} from "@mui/material";
import {
  CompoundInterscanApplication,
  InterscanAnalysisViewModel,
  Study,
  useGetApiCompoundInterscanApplicationsQuery,
  useGetApiInterscanApplicationsQuery
} from "../../../app/api/aiq-api";
import { enhancedApi } from "../../../app/api/enhancedApi";
import { ApplicationSelectMenu } from "../ApplicationSelectMenu";
import { useSelector } from "react-redux";
import { SelectedStudy } from "../../../app/selectors/studySelectors";
import { ApplicationState } from "../../../redux/store/ConfigureStore";
import { FilterMenu } from "../FilterMenu";
import {
  AllInterscanApplicationsSelector,
  AllCompoundInterscanApplicationsSelector,
} from "../../../app/selectors/applicationSelectors";
import { ClientSideInterscanAnalysisViewModel } from "../../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import {
  ClientSideInterscanAnalysisVMSelectorMethods
} from "../../../app/selectors/interscanAnalysisSelectors";
import CompoundInterscanCreateSingleScanTable from "./CompoundInterscanCreateSingleScanTable";
import { getFormattedDateFromString } from "../../../app/utilities/dateUtilities";

interface ICompoundInterscanCreatePopupProps {
  onCancel: () => void;
  selectedStudyId: string;
}

/**
 * This is a connected component that displays a list of Platform Studies
 */
export const CompoundInterscanCreatePopup: React.FunctionComponent<ICompoundInterscanCreatePopupProps> = (props) => {
  const selectedStudy: Study | undefined = useSelector((state: ApplicationState) => SelectedStudy(state as never));
  const compoundInterscanApplications = useSelector((state: ApplicationState) => AllCompoundInterscanApplicationsSelector(state as never));
  const interscanApplications = useSelector((state: ApplicationState) =>AllInterscanApplicationsSelector(state as never));
  const [activeInterscans, setActiveInterscanIds] = React.useState<Array<ClientSideInterscanAnalysisViewModel>>([]);
  const [selectedApplicationId, setSelectedApplicationId] = React.useState<string | undefined | null> (compoundInterscanApplications?.find((x) => x != null && !x.deprecated && !x.disabled)?.systemId);
  const [selectedApplication, setSelectedApplication] = React.useState<CompoundInterscanApplication | undefined>(compoundInterscanApplications?.find((s) => s.systemId == selectedApplicationId));
  const [doCreateCompoundInterscanAnalysis] = enhancedApi.endpoints.postApiCompoundInterscanAnalysesCreateAndInitialize.useMutation();
  const [loadInterscanAnalysisVMsQuery, loadInterscanAnalysisVMsResult] =  enhancedApi.endpoints.getApiStudiesByIdInterscanAnalysisViewModels.useLazyQuery();

  useGetApiCompoundInterscanApplicationsQuery();
  useGetApiInterscanApplicationsQuery();
  
  const [aggregateInterscanVMs, setAggregateInterscanVMs] = React.useState<Array<ClientSideInterscanAnalysisViewModel>>([]);

  // Pagination and Filtering State
  const [interscanPageNumber, setInterscanPageNumber] = React.useState<number>(0);
  const interscanPageSize: number = useSelector((state: ApplicationState) => state.scanAnalyses.interscanAnalysisPageSize);
  const [interscanPatientFilter, setInterscanPatientFilter] = React.useState<string | undefined>(undefined);

  React.useEffect(() => loadMoreInterscanAnalysisVMs(), []);

  React.useEffect(() => {
    setSelectedApplication(compoundInterscanApplications?.find((s) => s.systemId == selectedApplicationId));
  }, [selectedApplicationId, compoundInterscanApplications]);

  React.useEffect(() => {
    if (loadInterscanAnalysisVMsResult.isSuccess && !loadInterscanAnalysisVMsResult.isFetching) {
      var aggregateInterscanVMsCopy = [...aggregateInterscanVMs];
      let rawScans: Array<InterscanAnalysisViewModel> = loadInterscanAnalysisVMsResult.data?.result ?? [];
      let clientSideVms = ClientSideInterscanAnalysisVMSelectorMethods.GetClientSideInterscanAnalysisVMs(rawScans);

      clientSideVms.forEach((savm) => {
        if (!aggregateInterscanVMsCopy.find((x) => x.interscanAnalysis.id === savm.interscanAnalysis.id)) {
          aggregateInterscanVMsCopy.push(savm);
        }
      });
      setAggregateInterscanVMs(aggregateInterscanVMsCopy);
    }
  }, [loadInterscanAnalysisVMsResult]);


  React.useEffect(() => loadMoreInterscanAnalysisVMs(), [interscanPageNumber]);
  React.useEffect(() => {
    setActiveInterscanIds([]);
    setAggregateInterscanVMs([]);
    setInterscanPageNumber(0);
    loadMoreInterscanAnalysisVMs();
  }, [interscanPatientFilter, selectedApplication]);

  const handleApplicationSelectionChange = (selectedApplicationId: string | undefined) => {
    setSelectedApplicationId(selectedApplicationId);
    setActiveInterscanIds([]);
    setAggregateInterscanVMs([]);
    setInterscanPageNumber(0);
  };

  const getTruncatedId = (id: string): string => `${id.substring(0, 6)}...`;

  const getNumberOfPages = (): number => {
    return Math.ceil((loadInterscanAnalysisVMsResult?.data?.totalCount ?? 0) / interscanPageSize);
  };

  const onInterscanSelectionChange = (partialScanAnalysisId: string[]) => {
    var selectedInterscanAnalyses = aggregateInterscanVMs?.filter((x) =>
      partialScanAnalysisId.includes(getTruncatedId(x.interscanAnalysis.id!))
    );
    setActiveInterscanIds(selectedInterscanAnalyses);
  };

  const handleCreateClicked = () => {
    var ids = activeInterscans.map(x => x.interscanAnalysis.id!);
    doCreateCompoundInterscanAnalysis({
      compoundInterscanAnalysisCreateAndInitializeRequest: {
        compoundInterscanApplicationSystemId: selectedApplicationId,
        inputInterscanAnalysisIds: ids,
      },
    });
    props.onCancel();
  };

  const loadMoreInterscanAnalysisVMs = () => {
    if (selectedStudy) {
      loadInterscanAnalysisVMsQuery({
        id: selectedStudy.id!,
        pageNumber: interscanPageNumber,
        pageSize: interscanPageSize,
        patientIdentifierSearchPattern: interscanPatientFilter,
        compatibleInterscanApplicationFilter: selectedApplication?.systemId!,
      });
    }
  };

  const getDistinct = (values: string[]): string[] => {
    return [...new Set(values)];
  }

  const scanViewModelsToTableRows = (
    scanVms: Array<ClientSideInterscanAnalysisViewModel> | undefined | null
  ): Array<Array<string>> => {
    if (!scanVms) return [[]];
    return scanVms.map((scanVm) => {
      return [
        getDistinct(scanVm.sourceScanAnalysisGroups.map(x => x.map(y => y.patient!.patientIdentifier!)).flat()).join(", "),
        getDistinct(scanVm.sourceScanAnalysisGroups.map(x => x.map(y => y.timepoint.timepointName!)).flat()).join(", "),
        getDistinct(scanVm.sourceScanAnalysisGroups.map(x => x.map(y => getFormattedDateFromString(y.exam.examDate!))).flat()).join(","),
        scanVm.applicationTemplate.displayName!,
        getFormattedDateFromString(scanVm.dateCreated!.toString()),
        getTruncatedId(scanVm.interscanAnalysis?.id!) ?? "UNKNOWN",
      ];
    });
  };


  const getMaxSelectable = (): number => {
    if(selectedApplication == null)  return 0;
    const totalSelected = activeInterscans.length;
    const totalSlots =  selectedApplication.numberOfInputInterscanAnalyses!;

    return totalSlots - totalSelected;
  }

  return (
    <React.Fragment>
      <Paper sx={{ width: "100%", height: "100%", padding: "20px", display: "block" }}>
        <Typography variant="h4" sx={{ mr: 1 }} gutterBottom>
          Create Compound Interscan Analysis
        </Typography>
        <Divider />

        <Box sx={{ width: 800 }}>
          <ApplicationSelectMenu
            applications={compoundInterscanApplications ?? []}
            selectedApplicationId={selectedApplicationId ?? null}
            nullSelectLabel="Use Application from Manifest"
            onSelectionChanged={(applicationId: string | undefined) =>
              handleApplicationSelectionChange(applicationId)
            }
          />
        </Box>

        <Box sx={{ mt: 1, height: "calc(100% - 190px)" }}>
          {selectedApplication && scanViewModelsToTableRows(aggregateInterscanVMs).length > 0 && (
            <Box sx={{ height: "100%" }}>
              <Box sx={{ width: 600, height: 55 }}>
                <FilterMenu
                  filterValue={interscanPatientFilter}
                  onFilterChange={(updatedValue: string | undefined) =>
                    setInterscanPatientFilter(updatedValue)
                  }
                />
              </Box>
              <Box sx={{ display: "flex", height: "calc(100% - 55px)" }}>
                <CompoundInterscanCreateSingleScanTable
                  keyRowIndex={5}
                  maxSelect={getMaxSelectable()}
                  onSelectionChange={(data) => onInterscanSelectionChange(data)}
                  selected={activeInterscans.map((scan) => getTruncatedId(scan.interscanAnalysis.id!))}
                  assigned={Array.from(activeInterscans.values())
                    .flatMap((scan) => scan)
                    .map((scan) => getTruncatedId(scan.interscanAnalysis.id!))}
                  headCells={[
                    { id: "patiendIdentifier", numeric: false, date: false, label: "Patient Identifiers", width: 110 },
                    { id: "timepointName", numeric: false, date: false, label: "Timepoints", width: 110 },
                    { id: "examDate", numeric: false, date: false, label: "Exam Dates", width: 95 },
                    { id: "singleScanApplication", numeric: false, date: false, label: "Application", width: 170 },
                    { id: "createdDate", numeric: false, date: true, label: "Date Created", width: 50},
                    { id: "id", numeric: false, disablePadding: true, asTooltip: true, label: "Id", width: 50 },
                  ]}
                  data={scanViewModelsToTableRows(aggregateInterscanVMs)}
                />
              </Box>
            </Box>
          )}
          { !loadInterscanAnalysisVMsResult.isFetching &&
            loadInterscanAnalysisVMsResult.data?.result?.length == 0 &&
            selectedApplication &&
            interscanApplications &&
            selectedStudy  && (
              <React.Fragment>
                <Typography variant="subtitle1" sx={{ padding: 3 }}>
                  Study <strong>{selectedStudy.studyName}</strong> has no completed interscan analyses that
                  are compatible with Compound Interscan Application <strong>{selectedApplication.displayName}</strong>
                  .
                </Typography>
                <Typography variant="subtitle1" sx={{ padding: 3 }}>
                  The following Compound Interscan Applications are compatible with{" "}
                  <strong>{selectedApplication.displayName}</strong>:{" "}
                  <List dense={true} sx={{ m: 2 }}>
                    {selectedApplication.validInterscanApplicationInputs?.map((x) => (
                      <ListItem
                        key = {x.toString()}
                        sx={{ fontSize: ".75rem", display: "list-item", listStyleType: "disc", pl: 0 }}
                      >
                        {interscanApplications.find((s) => s.systemId == x)?.displayName}
                      </ListItem>
                    ))}
                  </List>
                </Typography>
              </React.Fragment>
            )}
          {loadInterscanAnalysisVMsResult.isFetching && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 3,
                height: "100%",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {aggregateInterscanVMs.length > 0 && interscanPageNumber + 1 < getNumberOfPages() && (
            <Box sx={{ display: "flex", justifyContent: "center", width: 600, height: 50 }}>
              {!loadInterscanAnalysisVMsResult.isFetching && (
                <Button
                  data-cy="LoadMoreButton"
                  onClick={() => setInterscanPageNumber(interscanPageNumber + 1)}
                >
                  Load more...
                </Button>
              )}
            </Box>
          )}
        </Box>

        <Grid
          container
          spacing={1}
          sx={{ mt: 1, height: 60 }}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexFlow: "row wrap",
              "& Button": {
                marginRight: "10px",
              },
            }}
          >
            <Button
              data-cy="createInterscanAcceptButton"
              variant="contained"
              color="primary"
              disabled={
                activeInterscans.length != selectedApplication?.numberOfInputInterscanAnalyses
              }
              onClick={handleCreateClicked}
            >
              Create
            </Button>
            <Button
              data-cy="createInterscanCancelButton"
              variant="contained"
              color="secondary"
              onClick={props.onCancel}
            >
              Cancel
            </Button>
            <Typography variant="h6">
              {activeInterscans.length} of{" "}
              {selectedApplication?.numberOfInputInterscanAnalyses?? 0} required interscan{" "}
              analyses selected
            </Typography>

          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
