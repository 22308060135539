import { Typography, Container, Box, useTheme } from "@mui/material";
import { useDroppable } from '@dnd-kit/core';

import React from "react";
import { InterscanProcessingCard } from "./InterscanProcessingCard";
import { Help } from "@mui/icons-material";
import { AiqTooltip } from "../common/AiqToolTip";
import { InterscanApplicationTaskTemplateApplicationStepTemplate } from "../../app/api/aiq-api";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";

interface IInterscanProcessingLaneProps {
  interscanAnalysisVMs: Array<ClientSideInterscanAnalysisViewModel>;
  stepTemplate: InterscanApplicationTaskTemplateApplicationStepTemplate;

  /**
   * The View Model for the currently dragged Single Scan Processing card (if any)
   */
  draggedScanAnalysis: ClientSideInterscanAnalysisViewModel | undefined;
}

export const InterscanProcessingLane: React.FunctionComponent<IInterscanProcessingLaneProps> = (props) => {
  const theme = useTheme();
  const canDropDraggedCard = (): boolean => {
    return (
      props.draggedScanAnalysis !== undefined &&
      props.draggedScanAnalysis!.canTransitionToStep(props.stepTemplate.systemId!)
    );
  };
  const {isOver , setNodeRef} = useDroppable({
    id: props.stepTemplate.systemId!
  });

  return (

    <Container
      data-cy="InterscanProcessingLane"
      disableGutters={true}
      ref={setNodeRef}
      sx={{
        backgroundColor:  isOver && canDropDraggedCard() ? theme.palette.secondary.main: theme.palette.secondary.light,
        borderRightStyle: "solid" as "solid",
        borderWidth: "thin",
        borderColor: theme.palette.secondary.dark,
        width: "100%",
      }}
    >
        <Box
          sx={{
            borderBottomColor: theme.palette.secondary.dark,
            borderBottomWidth: "thin",
            borderBottomStyle: "solid" as "solid",
            padding: "3px",
            paddingLeft: "8px",
            color: theme.palette.primary.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "36px",
          }}
        >
          <Typography variant="subtitle2" sx={{ fontSize: "0.96em", lineHeight: 1 }}>
            {props.stepTemplate.displayName}
            <AiqTooltip title={props.stepTemplate.description!}>
              <Help fontSize="small" color="disabled" sx={{ fontSize: "1.2em", marginLeft: "4px" }} />
            </AiqTooltip>
          </Typography>
        </Box>
        {props.interscanAnalysisVMs
          .sort((a: ClientSideInterscanAnalysisViewModel, b: ClientSideInterscanAnalysisViewModel) => {
            return b.dateCreated.getTime() - a.dateCreated.getTime();
          })
          .map((scanAnalysisVM, index) => (
            <div
              data-cy="InterscanProcessingCardDraggable"
            >
              <InterscanProcessingCard interscanAnalysisVM={scanAnalysisVM} />
            </div>
          ))}
    </Container>
  );
};
