import * as React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import {
  Grid,
  Typography,
  Button,
  Box,
  useTheme,
} from "@mui/material";
import { PlatformUser } from "../../app/api/aiq-api";

/**
 * This interface defines the shape of the data this component expects for its props.
 */
interface IUserFormProps {
  user: PlatformUser;
  onSave: (formData: IPlatformUserFormViewModel) => void;
  onCancel: () => void;
  isLoading: boolean;
  isSaving: boolean;
  error: string | undefined;
}

/**
 * This interface defines the shape of the data that the PlatformUserForm works with
 */
export interface IPlatformUserFormViewModel {
  platformUserName: string | undefined;
  externalIdentityProviderId: string | undefined;
  id: string | undefined;
}

/**
 * This is a dumb component that displays an editing UI for a Platform User
 */
export const PlatformUserForm: React.FunctionComponent<IUserFormProps> = (props) => {
  const [loadedValues, setLoadedValues] = React.useState<IPlatformUserFormViewModel | undefined>({
    platformUserName: props.user.platformUserName,
    externalIdentityProviderId: props.user.externalIdentityProviderId,
    id: props.user.id,
  } as IPlatformUserFormViewModel);
  const handleSubmit = (data: IPlatformUserFormViewModel) => {
    props.onSave(data);
  }
  const theme = useTheme();

  React.useEffect(() => {
    setLoadedValues({
      platformUserName: props.user.platformUserName,
      externalIdentityProviderId: props.user.externalIdentityProviderId,
      id: props.user.id,
    } as IPlatformUserFormViewModel);
  }, []);


  return (
    <React.Fragment>
      {loadedValues && (
        <Formik<IPlatformUserFormViewModel>
          initialValues={loadedValues}
          enableReinitialize
          validationSchema={Yup.object({
            platformUserName: Yup.string()
              .max(64, "Must be 64 characters or less")
              .required("Required"),
            externalIdentityProviderId: Yup.string().max(
              64,
              "Must be 64 characters or less"
            ),
          })}
          onSubmit={(data, { setSubmitting }) => {
            handleSubmit(data);
            setSubmitting(false);
          }}
        >
          {({ submitForm, dirty}) => (
            <Form>
              <Grid container spacing={3} sx={{p: 2}}>
                {/* User Name */}
                <Grid item xs={12} sm={6}>
                  <Field
                    name="platformUserName"
                    label="User Name"
                    fullWidth
                    autoFocus
                    variant="filled"
                    tabIndex="1"
                    component={TextField}
                    disabled={props.isSaving}
                  />
                </Grid>
                {/* External ID */}
                <Grid item xs={12} sm={6}>
                  <Field
                    name="externalIdentityProviderId"
                    label="External Identity Provider User ID"
                    fullWidth
                    tabIndex="2"
                    variant="filled"
                    component={TextField}
                    disabled={props.isSaving}
                  />
                </Grid>
                {/* Roles */}
                <Grid item xs={12}>
                  <Box sx={{
                      display: "flex",
                      flexFlow: "row wrap",
                      "& Button": {
                        marginRight: "10px",
                      },
                      }}>
                    {/* Submit button */}
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={props.isSaving || !dirty}
                      onClick={submitForm}
                    >
                      {props.isSaving ? "Saving..." : "Save"}
                    </Button>{" "}
                    {/* Cancel button */}
                    <Button
                      variant="contained"
                      onClick={props.onCancel}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      {/* Error display */}
      {props.error && (
        <Typography sx={{
          color: theme.palette.error.main,
          textAlign: "center",
          padding: "5px",}}>{props.error}</Typography>
      )}
    </React.Fragment>
  );
}
