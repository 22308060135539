import * as React from "react";
import { Route, Routes } from "react-router-dom";
import {
  Backdrop,
  Button,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./custom.css";
import { Home } from "./components/common/Home";
import { ManagePlatformUser } from "./components/platformUsers/ManagePlatformUser";
import { PlatformUserList } from "./components/platformUsers/PlatformUserList";
import { MainLayout } from "./components/common/MainLayout";
import { ManageStudy } from "./components/studyManagement/ManageStudy";
import { Studies } from "./components/studyManagement/Studies";
import { SingleScanProcessingDashboard } from "./components/scanProcessing/SingleScanProcessingDashboard";
import { NavigationConstants } from "./constants/NavigationConstants";
import { SingleScanAnalysis } from "./components/scanProcessing/SingleScanAnalysis";
import { authSlice, tryRefreshAuthToken } from "./app/slices/authSlice";
import { CurrentUserIsAdminSelector } from "./app/selectors/authSelectors";
import { enhancedApi } from "./app/api/enhancedApi";
import { AppDispatch, ApplicationState } from "./redux/store/ConfigureStore";
import { InterscanProcessingDashboard } from "./components/scanProcessing/InterscanProcessingDashboard";
import { InterscanAnalysis } from "./components/scanProcessing/InterscanAnalysis";
import { useGetApiPlatformUsersSelfQuery } from "./app/api/aiq-api";
import { CompoundInterscanAnalysis } from "./components/scanProcessing/compoundInterscan/CompoundInterscanAnalysis";
import { CompoundInterscanProcessingDashboard } from "./components/scanProcessing/compoundInterscan/CompoundInterscanProcessingDashboard";

export const App: React.FunctionComponent = () => {
  const [authenticationError, setAuthenticationError] = React.useState<string | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const {
    data: currentUser,
    isLoading: currentUserLoading,
    error: currentUserLoadError,
  } = useGetApiPlatformUsersSelfQuery();
  const [loadUserRoles, { data: userRoles, isLoading: userRolesLoading }] =
    enhancedApi.endpoints.getApiPlatformUsersByIdRoles.useLazyQuery();
  const userIsAdmin: boolean = useSelector((state: ApplicationState) => CurrentUserIsAdminSelector(state as never));
  const authenticating: boolean = useSelector((state: ApplicationState) => state.auth.authenticating);
  React.useEffect(() => {
    dispatch(tryRefreshAuthToken());
  }, []);

  React.useEffect(() => {
    if (currentUser !== undefined) {
      loadUserRoles({ id: currentUser!.id! });
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (currentUserLoadError !== undefined && !currentUserLoading && (currentUserLoadError as any).status === "FETCH_ERROR")  {
      setAuthenticationError("Failed to load user. Possible backend connection issue.");
    }
    if(authenticationError !== undefined && currentUserLoading) {
      setAuthenticationError(undefined)
    }
  }, [currentUserLoadError, currentUserLoading]);

  React.useEffect(() => {
    if (userRoles !== undefined && !userIsAdmin) {
      setAuthenticationError(
        "This app is only accessible by AIQ Admins. Please talk to an administrator to get the needed access."
      );
    }
  }, [userRoles]);

  const renderErrorDialog = () => {
    return (
      <Dialog
        open={authenticationError !== undefined}
        onClose={() => dispatch(authSlice.actions.logout())}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">{"Login Error"}</DialogTitle>
        <DialogContent>
          <Typography>{authenticationError}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => authSlice.actions.logout()} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
      />
      <Backdrop
        style={{ zIndex: 10, color: "#fff" }}
        open={authenticating || currentUserLoading || userRolesLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!(authenticating || currentUserLoading || userRolesLoading) && (
        <React.Fragment>
          {userIsAdmin && (
            <React.Fragment>
              <CssBaseline />
              <MainLayout>
                <Routes>
                  <Route path="/" element={<Home/>} />
                  <Route
                    path={`${NavigationConstants.platformUserPath}/:slug`}
                    element={<ManagePlatformUser/>}
                  />
                  <Route path={`${NavigationConstants.platformUserPath}`} element={<PlatformUserList/>} />
                  <Route path={`${NavigationConstants.studiesPath}/:slug`} element={<ManageStudy/>} />
                  <Route path={`${NavigationConstants.studiesPath}`} element={<Studies/>} />
                  <Route
                    path={`${NavigationConstants.singleScanProcessingPath}`}
                    element={<SingleScanProcessingDashboard />}
                  />
                  <Route
                    path={`${NavigationConstants.singleScanAnalysisPath}/:slug`}
                    element={<SingleScanAnalysis/>}
                  />
                  <Route
                    path={`${NavigationConstants.interscanProcessingPath}`}
                    element={<InterscanProcessingDashboard/>}
                  />
                  <Route
                    path={`${NavigationConstants.interscanAnalysisPath}/:slug`}
                    element={<InterscanAnalysis/>}
                  />
                  <Route
                    path={`${NavigationConstants.compoundInterscanProcessingPath}`}
                    element={<CompoundInterscanProcessingDashboard/>}
                  />
                  <Route
                    path={`${NavigationConstants.compoundInterscanAnalysisPath}/:slug`}
                    element={<CompoundInterscanAnalysis/>}
                  />
                </Routes>
              </MainLayout>
            </React.Fragment>
          )}
          {!userIsAdmin && <div>An unknown error has occurred</div>}
        </React.Fragment>
      )}

      {renderErrorDialog()}
    </React.Fragment>
  );
};
