import * as React from "react";
import { Container, Paper, Backdrop, CircularProgress, useTheme } from "@mui/material";
import { InterscanProcessingLane } from "./InterscanProcessingLane";
import { ScanProcessingConstants } from "../../constants/ScanProcessingConstants";
import { Toast } from "../common/Toast";
import { InterscanAnalysisViewModel, InterscanApplication } from "../../app/api/aiq-api";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { useSelector } from "react-redux";
import { ClientSideInterscanAnalysisStepViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisStepViewModel";
import { SelectedStudyInterscanAnalysisVMsSelector } from "../../app/selectors/interscanAnalysisSelectors";
import { enhancedApi } from "../../app/api/enhancedApi";
import { getErrorMessageFromRtkMutationResult } from "../../app/api/apiUtilities";
import {DndContext, DragStartEvent, DragEndEvent, useSensors, PointerSensor, useSensor} from '@dnd-kit/core';

// Derive the AppProps type info
interface IInterscanProcessingLanesProps {
  application: InterscanApplication,
  onStepTransition: () => void
}

/**
 * This is a connected component that displays a series of lanes representing a step in interscan analysis
 */
export const InterscanProcessingLanes: React.FunctionComponent<IInterscanProcessingLanesProps> = (props) => {
  const [draggedScanAnalysis, setDraggedScanAnalysis] = React.useState<ClientSideInterscanAnalysisViewModel | undefined>(undefined);
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [doTransition, transitionResult] = enhancedApi.endpoints.postApiScanAnalysisStepsByIdTransition.useMutation();
  const interscanAnalysisVMs = useSelector(SelectedStudyInterscanAnalysisVMsSelector);
  const theme = useTheme();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )

  const scanAnalysisStepById = (stepId: string): ClientSideInterscanAnalysisStepViewModel | undefined => {
    let allSteps =  interscanAnalysisVMs.flatMap(savm => savm.orderedScanAnalysisSteps);
    return allSteps.find(step => step.scanAnalysisStep.id === stepId)
  }

  React.useEffect(() => {
    var targetStepTemplateSystemId = transitionResult.originalArgs?.scanAnalysisStepTransitionRequest.targetStepSystemId ?? "Unknown";
    var originStepId = transitionResult.originalArgs?.scanAnalysisStepTransitionRequest.currentStepId ?? "Unknown";
    var originStepVM = scanAnalysisStepById(originStepId);
    var targetStepVM = originStepVM?.parentInterscanAnalysisViewModel?.applicationTemplate?.steps?.find(step => step.systemId === targetStepTemplateSystemId);
    if(transitionResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(transitionResult.error);
      setSnackbarText(
        `FAILED to Transition Interscan Analysis ${originStepVM!.parentInterscanAnalysisViewModel.interscanAnalysis.id} from 
        ${ originStepVM?.stepTemplate.displayName } to ${targetStepVM?.displayName} - ${errorMessage}`
      );  
    } else if(transitionResult.isSuccess ) {
      setSnackbarText(
        `Transitioned Interscan Analysis ${originStepVM!.parentInterscanAnalysisViewModel.interscanAnalysis.id} from 
        ${ originStepVM?.stepTemplate.displayName } to ${targetStepVM?.displayName}`
      );
      props.onStepTransition();
    }
  }, [transitionResult])

  const onDragStart = (start: DragStartEvent) => {
    let draggedCard = interscanAnalysisVMs?.find(
      (card: InterscanAnalysisViewModel) => card.interscanAnalysis?.id === start.active.id
    );
    setDraggedScanAnalysis(draggedCard);
  }

  const onDragEnd = (event: DragEndEvent) => {
    let destinationStep = props.application.steps?.find(
      (step) => step.systemId === event.over?.id
    );
    if (destinationStep) {
      let currentStep = draggedScanAnalysis!.currentStep;
      if(draggedScanAnalysis && draggedScanAnalysis.canTransitionToStep(destinationStep.systemId!)) {
        try {
          let currentStepId: string = currentStep.scanAnalysisStep.id!

          let transitionRequest = {
            currentStepId: currentStepId,
            targetStepSystemId: destinationStep.systemId!,
          };
          doTransition({ id: currentStepId, scanAnalysisStepTransitionRequest: transitionRequest })
        } catch (error) {
          let errMesg = `Failed to transition Scan Analysis ${
            draggedScanAnalysis!.interscanAnalysis.id
          } from ${currentStep.stepTemplate.displayName} to ${destinationStep.displayName}: ${error instanceof Error ? error.message : "unknown"}`;
          setSnackbarText(errMesg);
          console.error(errMesg);
        }
      }
    } else {
      console.error(`Failed to identify destination component`);
    }
    setDraggedScanAnalysis(undefined);
    return;
  }

  const renderLanes = () => {
    return (
      <React.Fragment>
        {<Backdrop
          sx={{ zIndex: theme.zIndex.drawer + 1, color: "#fff"}}
          open={transitionResult.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>}
        <DndContext sensors={sensors} onDragEnd={(event) => onDragEnd(event)} onDragStart={(event) => onDragStart(event)}>
        <div style={{ display: "inline-block"}}>
            <Paper sx={{
              display: "flex",
              marginBottom: "20px",
              justifyContent: "flex-start"
            }}
            data-cy="InterscanProcessingLanes">
              {props.application.steps?.map((step) => (step.systemId !== ScanProcessingConstants.stepSystemIds.scanIngest && (
                <Container
                  key={step.systemId}
                  disableGutters={true}
                  sx={{
                    width: 275,
                    display: "flex",
                    margin: 0
                  }}
                >
                  <InterscanProcessingLane
                    stepTemplate={step}
                    draggedScanAnalysis={draggedScanAnalysis}
                    interscanAnalysisVMs={interscanAnalysisVMs?.filter((card) =>
                      card.applicationTemplate.systemId === props.application.systemId &&
                      card.currentStep.stepTemplate.systemId === step.systemId
                      )}
                  />
                </Container>)
              ))}
            </Paper>
          </div>
        </DndContext>
      </React.Fragment>
    );
  }
    return (
      <React.Fragment>
        {props.application && renderLanes()}
          <Toast
            open={snackbarText !== undefined}
            onClose={() => setSnackbarText(undefined)}
            text={snackbarText ?? ""}
            autoHideDurationMillisecs={6000}
          />
      </React.Fragment>
    );


}


